var site = site || {};
site.productView = site.productView || {};

site.productView.InvBISList = function($listNode) {
  var that = {
    $listNode : $listNode
  };
  that.productId = that.$listNode.attr("data-product-id");

  that.$listNode.find('.js-product-inventory-status').on("inventory_status_stored", function(e) {
    that.updateBISStatus();
    e.stopPropagation();
  });

  that.updateBISStatus = function() {
    var skuId = that.$listNode.find('.js-product-inventory-status').attr("data-sku-id");
    var skuData = $(document).data(skuId);
    if (!skuData || !skuData.INVENTORY_STATUS) return null;
    if (skuData.INVENTORY_STATUS == 2 || skuData.INVENTORY_STATUS == 3) {
      var status = ".product_status_" + skuData.INVENTORY_STATUS;
      var status_type = (skuData.INVENTORY_STATUS == 2) ? 'BIS' : 'CS';
      $(status, that.$listNode).removeClass('hidden');
      if (!$(status, that.$listNode).hasClass('bis-processed')) {
        that.setWaitlistLink(skuData, $listNode, status_type, 'js-product-bis a');
        $(status, that.$listNode).addClass('bis-processed');
      }
    }
    else{
      $('.js-product-bis', that.$listNode).addClass('hidden');
    }
  };

  that.updateBISStatus();

  that.$listNode.on('sku:select', function(e, skuData) {
    that.$listNode.attr("data-sku-id", skuData.SKU_ID);
    that.updateBISStatus();
    e.stopPropagation();
  });

  that.setWaitlistLink = function(sku, $listNode, req_type, req_link) {
    // set waitlist link
    $('.' + req_link, $listNode).on("click", function(e, link) {
      e.preventDefault();
      $(link).unbind();
      var sku_id = sku.SKU_ID;
      var sku_base_id = sku_id.replace("SKU", "");
      var file = '/waitlist/signup.tmpl?SKU_BASE_ID=' + sku_base_id + '&REQUEST_TYPE=' + req_type;
      var options = {
        includeBackground: false,
        includeCloseLink: true,
        hideOnClickOutside: true,
        cssStyle: {border:'1px solid #5dba98', width: 'auto', height: 'auto', padding: '0'}
      };
      options.content = '<iframe class="bisPcIframe" scrolling="no" frameborder="0" src="'+file+'"></iframe>';
      generic.overlay.launch(options);
    });
  };

  return that;
};

(function($) {
  Drupal.behaviors.ELB_invBISList = {
    attach: function(context, settings) {
      $('.product-full__description, .product_brief').each( function() {
        var invBISList = site.productView.InvBISList($(this));
      });
    }
  };
})(jQuery);

